import * as React from 'react'
import { HeadFC } from 'gatsby'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
}

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <h1>Oops !</h1>
        <p>
          Oops 😔, La page que vous demandez est introuvable.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code style={codeStyles}>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <SEO title="Lakshmi - Page introuvable" pathname="/404"></SEO>
